import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const author = "Campeones financieros"

const routes = [
  {
    path: '/home',
    name: 'home',
    meta: {title: 'Inicio -' + author},
    beforeEnter() {location.href = 'https://omareducacionfinanciera.com/blog'}
  },
  {
    path: '/',
    name: 'ElAuth',
    meta: {title: 'Bienvenido -' + author,  'page': 'Bienvenido'},
    component: ()  => import('../views/ElAuth'),
    beforeRouteUpdate: function () {
      var OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
      // day hour  min  sec  msec
      if (OneDay < localStorage.getItem('stored:explorer-server-cookie')) {
        localStorage.clear()
        // The yourDate time is more than 1 days from now
      }

    }
  },
  {
    path: '/valuacion-de-acciones',
    name: 'StockValuation',
    meta: {title: 'Valuación de acciones -' + author,  'page': 'Valuación de acciones'},
    component: ()  => import('../views/StockValuations'),
    beforeEnter: isAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//define title page
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})


function isAuthenticated(to, from, next) {
  if (localStorage.getItem('stored:explorer-server-cookie')) {
    next();
    return
  }
  router.push('/')
}


export default router
